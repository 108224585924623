<template>
  <div class="tag-list">
    <p>{{ $t("product.tag") }}</p>
    <br />
    <el-tag
      :key="`taglist_${index}`"
      v-for="(item, index) in list"
      :disable-transitions="false"
    >
      {{ item.tag.tagName }}
      <el-popconfirm
        :title="$t('common.confirmDelete')"
        @confirm="handleDelete(item)"
      >
        <i slot="reference" class="el-icon-close close-hover" />
      </el-popconfirm>
    </el-tag>

    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model.trim="inputValue"
      maxlength="12"
      ref="saveTagInput"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button
      class="button-new-tag"
      v-else
      icon="el-icon-plus"
      type="primary"
      plain
      @click="showInput"
    ></el-button>
    <p>
      <el-button type="primary" class="tag-next" @click="next">
        {{ $t("common.next") }}
      </el-button>
    </p>
  </div>
</template>


<script>
import { AddTag, GetTagList, DelTagRealtion } from "@/utils/api";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      type: 1,
      list: [],
      inputVisible: false,
      inputValue: "",
    };
  },
  created() {
    this.id && this.getList();
  },
  methods: {
    getList() {
      GetTagList({ bindId: this.id, bindType: this.type }) //  bindType: 1，spu.id
        .then((res) => {
          console.log("GetTagList-res:", res);
          this.list = res.data.rows;
          this.error = null;
        })
        .catch((err) => {
          console.log("GetTagList-err:", err);
          this.error = err;
        });
    },
    handleDelete(item) {
      console.log("handleDelete-tag:", item, this.list);
      this.list.splice(this.list.indexOf(item.bindId), 1);
      DelTagRealtion({
        bindId: item.bindId,
        tagId: item.tag.id,
        bindType: item.bindType,
      })
        .then((res) => {
          console.log("AddTag-res:", res);
          this.getList();
        })
        .catch((err) => {
          console.log("AddTag-err:", err);
        });
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      if (this.inputValue.length > 0) {
        //  判断输入字符串不为空
        if (this.list.some((v) => v.tag.tagName === this.inputValue)) {
          //  判断输入的字符串已存在列表
          this.$message({
            type: "warning",
            message: this.$t("product.warningmsg"),
          });
        } else {
          AddTag({
            tagName: this.inputValue,
            bindId: this.id,
            bindType: this.type,
          })
            .then((res) => {
              console.log("AddTag-res:", res);
              this.getList();
            })
            .catch((err) => {
              console.log("AddTag-err:", err);
            });
        }
        this.closeInput();
      } else {
        this.closeInput();
      }
    },

    closeInput() {
      this.inputVisible = false;
      this.inputValue = "";
    },

    next() {
      this.$emit("success", {});
    },
  },
};
</script>


<style lang="scss">
.tag-list {
  .el-tag {
    margin-right: 10px;
  }
  .button-new-tag {
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    white-space: pre;
    width: 90px;
    vertical-align: bottom;
  }
  .close-hover {
    &:hover {
      color: #fff;
      background-color: #409eff;
    }
  }
  .tag-next {
    display: block;
    width: 100%;
    margin-top: 10px;
    max-width: 300px;
  }
}
</style>